.gradient__bg_hitungbiaya {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  background-image: url("../../../assets/image/unsplash_FPKnAO-CF6M_kontak.png");
  background-repeat: no-repeat;
  height: 780px;
  /* background-size:100% auto; */

  max-width: 1400px;
  margin: auto;
}



.hasil_hitungan {
  max-width: 1400px;
  margin: auto;
  margin-top: -50px;
}

._hitungbiaya *:last-child {
  margin-bottom: 0;
}

.box_hitungbiaya-content {
  background: none repeat scroll 0 0 #ffffff;
  background-color: #f3f3f3;
  padding: 25px 55px 45px 55px;
  position: relative;
  z-index: 1;
  margin-top: -150px;
}

.hitung {
  position: absolute;
  width: 156px;
  height: 42px;
  left: 90%;
  top: 470px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: linear-gradient(90deg, #008bd9 25%, #56c3ff 81.35%);
  border-radius: 10px;
  color: white;
  margin: auto;
}

.hitung_biaya {
  width: 156px;
  height: 42px;
  background: linear-gradient(90deg, #008bd9 25%, #56c3ff 81.35%);
  border-radius: 10px;
  color: white;
}
.container-btn-hitung {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container-btn-hitung2 {
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 850px) {
    .hitungbiaya-container1 {
        margin-top: 15rem;
    }
  .box_hitungbiaya *:last-child {
    margin-bottom: 0;
  }
  .container-buttonhitungbiaya {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .box_hitungbiaya-4 {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        position: relative;
        width: 100%;
        margin: 20px;
        max-width:1400px;
        margin:auto;
        position:relative;
        height:950px; 
        
    } */

  .box_hitungbiaya-content {
    background: none repeat scroll 0 0 #ffffff;
    background-color: #f3f3f3;
    padding: 25px 55px 45px 55px;
    position: relative;
    z-index: 1;
    margin-top: -150px;
  }

  .hitung {
    position: absolute;
    width: 156px;
    height: 42px;
    left: 85%;
    top: 860px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background: linear-gradient(90deg, #008bd9 25%, #56c3ff 81.35%);
    border-radius: 10px;
    color: white;
    margin: auto;
  }
}
