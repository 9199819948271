.gradient__bg_artikel{

    
     /* ff 3.6+ */
     background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, white 100%);

     /* safari 5.1+,chrome 10+ */
     background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, white 100%);
 
     /* opera 11.10+ */
     background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, white 100%);
 
     /* ie 10+ */
     background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, white 100%);
 
     /* global 92%+ browsers support */
     background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, white 100%);
 
     background-image: url("../../../assets/image/unsplash_FPKnAO-CF6M.png");
     background-repeat: no-repeat;
     -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 600px;


}

.artikel_bg{

     max-width:1200px;
     margin:auto;


}


div.gallery_artikel {
     border: 1px solid #ccc;
     /* background: linear-gradient(180deg, #62C7FF 0%, #327CA5 100%);  */
     border-radius: 30px;
     height:300px;
   
   }
   
   div.gallery_artikel:hover {
     border: 1px solid #777;
   
   }
   
   div.gallery_artikel img {
     width: 100%;
     height: auto;
 
     
   }
   
   div.desc_artikel {
     padding: 15px;
     text-align: center;
     color:white;
   }

   .desc-2{
    color:#0076B8;

   }



 
   .button_more{

     box-sizing: border-box;
     position: absolute;
     width: 128px;
     height: 30px;
     border: 1px solid #000000;
     margin-top:-20px;


   }

   
h1 {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

img {
height: auto;
max-width: 100%;
vertical-align: middle;

}

.btn {
color: black;
padding: 0.8rem;
font-size: 14px;
text-transform: uppercase;
border-radius: 4px;
font-weight: 400;
display: block;
width: 50%;
cursor: pointer;
border: 1px solid black;
background: transparent;
margin-left:90px !important;
}

.btn:hover {
background-color: rgba(255, 255, 255, 0.12);
}


.btn_head {
  color: black;
  padding: 0.3rem;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  display: block;
  width: 35%;
  cursor: pointer;
  border: 1px solid black;
  background: transparent;
  margin:20px;
  }
  
  .btn_head:hover {
  background-color: rgba(255, 255, 255, 0.12);
  }



.cards {
display: flex;
flex-wrap: wrap;
list-style: none;
margin: 0;
padding: 0;
margin-top: -15px;
}

.cards_item {
display: flex;
padding: 1rem;
}

@media (min-width: 40rem) {
.cards_item {
  width: 100%;
}
}

@media (min-width: 56rem) {
.cards_item {
  width: 100%;
}
}

.card {
background-color: white;
border-radius: 0.25rem;
box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
display: flex;
flex-direction: column;
overflow: hidden;
}

.card_content {
padding: 1rem;
background: linear-gradient(to bottom left, white 40%, white 100%);
}

.card_title {
color: black;
font-size: 1.1rem;
font-weight: 700;
letter-spacing: 1px;
text-transform: capitalize;
margin: 0px;
}

.card_text {
color: black;
font-size: 0.875rem;
line-height: 1.5;
margin-bottom: 1.25rem;    
font-weight: 400;
}
.made_by{
font-weight: 400;
font-size: 13px;
margin-top: 35px;
text-align: center;
}


/* daerah card header */
.card-header {
  display:block;
  width:100%;
  max-width:800px;
 }
 img {
  width: 100%;
  height: auto;


 }
 .innerLeft {
  display:inline-block;
  margin-right:-4px;
  vertical-align:top;
  width:30%;
  text-align:center;
  padding:5px;
 }
 .innerRight {
  display:inline-block;
  vertical-align:top;
  width:70%;
  text-align:left;
  padding:5px;
 }
 .card-text {
  display:inline-block;
 }


 .horizontal-card {
  position: relative;
  display: flex;
  border: 0px solid gray;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
}
.horizontal-card img {
  width: 200px;
  height: 221px;
  border-bottom: 30px solid  transparent;
}


.horizontal-card .horizontal-card-body {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.horizontal-card .horizontal-card-footer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 30px;
  display: flex;
  align-items: center;
}
.horizontal-card .horizontal-card-footer span {
  width: 200px;
  display: inline-block;
  margin-left:50px;
}
.horizontal-card .horizontal-card-footer a {
  margin-left: 10px;
}


/* grid 3 */

.horizontal-card .img-grid-3 {
  width: 145px;
  height: 142px;
  border-bottom: 30px solid  transparent;
}



@media only screen and (max-width: 500px) {



     }





     