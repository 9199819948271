body {
  background: var(--color-bg);
}

.gradient__bg_layanan {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  background-image: url("../../../assets/image/unsplash_FPKnAO-CF6M-1.png");
  background-repeat: no-repeat;
  height: 600px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.setgambartombol {

}

.fiturgalery_layanan {
  max-width: 1200px;
  margin: auto;
  height: 1100px;
  position: relative;
}

.buttons_cards_layanan {
  max-width: 1400px;
  
}

.card_layanan {
  background-color: dodgerblue;
  color: white;
  padding: 1rem;
  height: 3rem;
}

.border_garis_tentangkami {
  border-style: inset;
  width: 15%;
  height: 0px;
  left: 800px;
  top: 600px;
  border: 5px solid #ffffff;
  margin: auto;
  color: white;
}

div.gallery_layanan {
  border: 1px solid #ccc;
  background: linear-gradient(180deg, #62c7ff 0%, #327ca5 100%);
  border-radius: 30px;
  height: 200px;
}

div.gallery_layanan:hover {
  border: 1px solid #777;
}

div.gallery_layanan img {
  width: 70%;
  height: auto;
}

div.gallery_layanan {
  padding: 15px;
  text-align: center;
  color: white;
  margin-left: 80px;
}

.desc-2 {
  color: #0076b8;
}

.gpt3__whatgpt3_tentangkami {
  margin-top: 330px;
  height: 280px;
  padding-top: 42px;
  background: linear-gradient(91.11deg, #0f8fd7 27.64%, #58b5e9 59.26%);
  border-radius: 0px;
}

.gpt3__whatgpt3_tentangkami_griddua {
  height: 350px;
  padding-top: 42px;

  background: linear-gradient(
    92.73deg,
    #b9cce4 35.92%,
    #7698c2 49.07%,
    #0076b8 67.27%
  );
  border-radius: 0px;
}

.distance_text_kedua_gridsatu {
  margin-top: -20px;
}
.distance_gambar {
  margin-top: -100px;
}

.distance_gambar_kedua {
  margin-top: -100px;
}

@media (max-width: 500px) {
  .distance_gambar {
    margin-top: 0px;
  }

  .distance_gambar_kedua {
    margin-top: 160px;
  }

  .distance_text_kedua {
    margin-top: 600px;
  }

  .distance_text_kedua_griddua {
    margin-top: -450px;
  }

  .gradient__bg_tentangkami {
    height: 650px;
  }

  .gpt3__whatgpt3-container {
    margin-top: -20px;
  }

  .gpt3__whatgpt3_tentangkami {
    height: 370px;
  }

  .buttons_cards_layanan {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    margin-left: 0%;
    position: relative;
    left: 0%;
    transform: translate(0%, 0%);
  }
}

.responsive-img {
  max-width: 80%;
  display: flex;
  align-items: center;
}
