.gradient__bg_kontak {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  background-image: url("../../../assets/image/unsplash_FPKnAO-CF6M_kontak.png");
  background-repeat: no-repeat;
  height: 520px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.whatsappinfo {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.img-wa {
  width: 40px;
  height: 40px;
}
.fiturgalery_kontakkami {
  max-width: 1200px;
  margin: auto;
  height: 600px;
  position: relative;
}

.box_kontak *:last-child {
  margin-bottom: 0;
}

.box_kontak-4 {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;
  margin: 20px 0px 0px 40px;
  max-width: 1400px;
  margin: auto;
  height: 400px;
}

.box_kontak-content {
  background: none repeat scroll 0 0 #ffffff;
  background-color: #f3f3f3;
  padding: 25px 55px 45px 55px;
  position: relative;
  z-index: 1;
  margin-top: 50px;
}
.box_kontak-4:after {
  border-radius: 0 0 50% 50% / 0 0 20px 20px;
  bottom: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  content: "";
  height: 20px;
  left: 10px;
  position: absolute;
  right: 10px;
}

.kontak_bg {
  max-width: 1200px;
  margin: auto;
}

@media screen and (max-width: 500px) {
  /* 
    .submit_kontak{

        position: absolute;
        width: 156px;
        height: 42px;
        left: 65%;
        top: 400px;
        -webkit-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        transform: translate(-50%,0);
        background: linear-gradient(90deg,#008BD9 25%,#56C3FF 81.35%);
        border-radius: 10px;
        color: white;
        margin: auto;
        


       } */

  .box *:last-child {
    margin-bottom: 0;
  }

  .box-4 {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
    margin: 20px 0px 0px 40px;
    max-width: 1200px;
    margin: auto;
  }

  .box-content {
    background: none repeat scroll 0 0 #ffffff;
    background-color: #f3f3f3;
    padding: 25px 55px 45px 55px;
    position: relative;
    z-index: 1;
    margin-top: -150px;
  }
  .box-4:after {
    border-radius: 0 0 50% 50% / 0 0 20px 20px;
    bottom: 0;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    content: "";
    height: 20px;
    left: 10px;
    position: absolute;
    right: 10px;
  }

  .box_kontak-4 {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
    margin: 20px 0px 0px 40px;
    max-width: 1200px;
    margin: auto;
    height: 450px;
  }
}

.submit_kontak {
  position: absolute;
  width: 156px;
  height: 42px;
  left: 80%;
  top: 350px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: linear-gradient(90deg, #008bd9 25%, #56c3ff 81.35%);
  border-radius: 10px;
  color: white;
  margin: auto;
}
