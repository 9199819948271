/* MARGIN BACKGRUND */
.padding-lr {
  margin: auto;
}
/* END MARGIN BACKGROUND */
.gpt3__whatgpt3 {
  display: flex;
  max-width: 1300px;
  flex-direction: column;
  padding: 2rem;
  background: var(--color-bg);
  border-radius: 50px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* height:1800px; */
}

.container-utama-cekresi {
  background-color: #56c3ff;
}

.gpt3-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-background {
  background-color: white;
}
.timeline-wrapper {
  /* max-height: 400px; */
  padding-top: 30px;
  overflow-y: auto;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline > li {
  padding: 0 0 20px 20px;
  border-left: 1px solid #e6e6e6;
}
ul.timeline > li.active {
  border-left: 1px solid #e6e6e6;
}
ul.timeline > li.last {
  border-left: none;
}
ul.timeline > li:before {
  content: " ";
  background: #e6e6e6;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  left: 22px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

ul.timeline > li.active:before {
  background: #e6e6e6;
}

.headerjudulbanner {
  position: absolute;
  width: 1045px;
  height: 174px;
  left: 50%;
  color: white;
  top: 0px;
  font-size: 50px;
  transform: translate(-50%, 0%);
  background: rgba(73, 124, 231, 0.67);
  border-radius: 0px 0px 80px 80px;
}
/* .bg-color {
    background-color: #FFFFFF;
} */

/* Resi */
.mg-resi {
  height: 50hv;
  width: 1300px;
  margin-top: 2.5rem;
  margin-left: 20rem;
  margin-right: 20rem;
}
/* end resi */

.gpt3__whatgpt3_formhitung {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: var(--color-bg);
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 630px;
}

.gpt3__whatgpt3_formhitunghome {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: var(--color-bg);
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* height:630px;  */
}

.gpt3__whatgpt3_formgalery {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: var(--color-bg);
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 500px;
}
.gpt3__whatgpt3-feature {
  display: flex;
}
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
  margin: 0;
}

.gpt3__whatgpt3-feature .gpt3__features-container__feature-text {
  max-width: 700px;
}
.gpt3__whatgpt3-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0rem 0 2rem;
}

.gpt3__whatgpt3-heading h4 {
  line-height: 45px;
  font-weight: 800;
  max-width: 510px;
  font-family: var(--font-family);
  color: #0f8ed6;
}

.gpt3__home_hitungbiaya {
  text-align: center;
  position: absolute;
  width: 156px;
  height: 42px;
  left: 50%;
  top: 420px;
  transform: translate(-50%, 0);
  background: linear-gradient(90deg, #008bd9 25%, #56c3ff 81.35%);
  border-radius: 10px;
  margin-top: 5px;
}

.gpt3__home_hitungbiaya p {
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.gpt3__home_cekresi {
  text-align: center;
  width: 156px;
  height: 42px;
  background: linear-gradient(90deg, #008bd9 25%, #56c3ff 81.35%);
  border-radius: 10px;
  font-size: 20px;
  color: white;
  font-weight: bold;
}
.button-cek-resi {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.gpt3__galery {
  /* text-align: center; */
  position: absolute;
  width: 609px;
  height: 83px;
  left: 50%;
  top: 0px;
  transform: translate(-50%, 0);
  background: linear-gradient(180deg, #0074b5 0%, #13a4f4 53.65%, #13a4f4 100%);
  border-radius: 0px 0px 50px 50px;
}

.gpt3__galery {
  font-size: 16px;
  color: white;
  font-weight: bold;
}
.mt-fiturgalery{
    margin-top: 70px;
}
.mb-fiturgalery{
    margin-top: 10px;
}
.buttons_cards_home {
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.container-utama-fiturgalery {
    width: 100%;
    display: flex;
    justify-content: center;
}

.card_home {
  background-color: dodgerblue;
  color: white;
  padding: 1rem;
  height: 3rem;
}

div.gallery_home {
  border: 1px solid #ccc;
  background: linear-gradient(180deg, #62c7ff 0%, #327ca5 100%);
  border-radius: 30px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-via {

}

div.gallery_home:hover {
  border: 1px solid #777;
}
.image-fiturgalery {
    padding: 30px;
    width: 80%;
    height: 80%;
}
.container-fiturgalery {
    width: 350px;
}

.gallery_home img {
  width: 100%;
  height: auto;
}

div.gallery_layanan {
  padding: 15px;
  text-align: center;
  color: white;
  margin-left: 80px;
}

.desc-2 {
  color: #0076b8;
}

.fiturgalery {
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.gpt3__header_hubungi_kami p {
  margin-top: 10px;
}

.Box {
  margin: 40px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* overflow: hidden; */
  background: linear-gradient(180deg, #62c7ff 0%, #327ca5 100%);
  border-radius: 30px;
  height: 283px;
  width: 278px;
  border-radius: 30px;
}

div.gallery {
  border: 1px solid #ccc;
  background: linear-gradient(180deg, #62c7ff 0%, #327ca5 100%);
  border-radius: 30px;
  width: 250px;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  padding: 15px;
  text-align: center;
  color: white;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 50px;
  float: left;
  width: 50%;
}

.responsive-choice {
    margin: 20px;
  }

.buttons_cards {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.container-hubungikami {
  display: flex;
  justify-content: center;
}

.buttons_cards_2 {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  margin-left: 0px;
}

.card_home {
  padding: 1rem;
  height: 3rem;
  /* background: linear-gradient(90deg, #008BD9 25%, #56C3FF 81.35%); */

  background: #ffffff;
  border: 0.5px solid rgba(59, 166, 226, 0.77);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #0f8ed6;
}

.gpt3_home_fitur_bg {
  background-image: url("../../../assets/image/Rectangle 83.png");
  /* height:680px ; */
  background-repeat: no-repeat;
  max-width: 1200px;
  margin: auto;
  position: relative;
}

.gpt3_home_galery_bg {
  background-image: url("../../../assets/image/bg-jam.png");
  height: 280px;
  margin: auto;
  object-fit: contain;
  position: relative;
  margin-top: 50px;
}

.gpt3_body {
  max-width: 2000px;
  margin: auto;
}

.gpt3__whatgpt3-heading p {
  font-family: var(--font-family);
  font-weight: 500px;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-subtext);
  cursor: pointer;
}
.gpt3__whatgpt3-container {
  display: flex;
  flex-direction: wrap;
  flex-direction: row;
  margin-top: 2rem;
}
.gpt3__whatgpt3-container .gpt3__features-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature-text {
  margin-top: 0.5rem;
}

@media screen and (max-width: 800px) {
  .gpt3__whatgpt3-container {
    flex-direction: column;
  }
  .gpt3__whatgpt3-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .gpt3__whatgpt3-heading p {
    margin-top: 1rem;
  }

  .mg-resi {
    margin-left: 0;
    margin-right: 0;
  }
  .gpt3__whatgpt3 {
    margin: 1rem;
  }
  .buttons_cards_home {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 850px) {
  .gpt3__whatgpt3-container {
    flex-direction: column;
  }
  .gpt3__whatgpt3-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .gpt3__whatgpt3-heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .gpt3__whatgpt3-container {
    flex-direction: column;
  }
  /* Customizing feature component as per needs */
  .gpt3__whatgpt3-feature .gpt3__features-container__feature {
    flex-direction: column;
  }
  .text-via {
    font-size: 12px;
  }

  /* Customizing feature component as per needs */
  .gpt3__whatgpt3-feature .gpt3__features-container__feature-text {
    margin-top: 0.5rem;
  }

  .gpt3__whatgpt3-heading h1 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (max-width: 450px) {
    .buttons_cards {
        width:300px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
  }

@media screen and (max-width: 350px) {
  /* Customizing feature component as per needs */
  .gpt3__whatgpt3-container .gpt3__features-container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }
}

.garis_batas {
  position: absolute;
  width: 94px;
  left: 180px;
  top: 114px;
  border: 1px inset #000000;
  transform: rotate(90deg);
}

.garis_batas_2 {
  position: absolute;
  width: 94px;
  height: 0px;
  left: 400px;
  top: 114px;
  border: 1px inset #000000;
  transform: rotate(90deg);
}

.garis_batas_3 {
  position: absolute;
  width: 94px;
  height: 0px;
  left: 830px;
  top: 114px;
  border: 1px inset #000000;
  transform: rotate(90deg);
}

.garis_batas_4 {
  position: absolute;
  width: 94px;
  height: 0px;
  left: 1050px;
  top: 114px;
  border: 1px inset #000000;
  transform: rotate(90deg);
}

.timeline-wrapper {
  /* max-height: 400px; */
  padding-top: 30px;
  overflow-y: auto;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline > li {
  padding: 0 0 20px 20px;
  border-left: 1px solid #e6e6e6;
}
ul.timeline > li.active {
  border-left: 1px solid #e6e6e6;
}
ul.timeline > li.last {
  border-left: none;
}
ul.timeline > li:before {
  content: " ";
  background: #e6e6e6;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  left: 22px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

ul.timeline > li.active:before {
  background: #e6e6e6;
}

/* '2xl': { 'max': '1535px' },
  // => @media (max-width: 1535px) { ... }

  'xl': { 'min': '1279px' },
  // => @media (max-width: 1279px) { ... }

  'lg': { 'min': '821px' },
  // => @media (max-width: 1023px) { ... }

  'md': { 'max': '820px' },
  // => @media (max-width: 767px) { ... }

  'sm': { 'max': '639px' },
  // => @media (max-width: 639px) { ... }
}, */

@media only screen and (max-width: 639px) {
  .sm {
  }

  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 820px) {
  .md {
  }
}

@media only screen and (max-width: 820px) {
  .md {
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }

  .gpt3__galery {
    /* text-align: center;
        position: absolute; */
    width: 300px;
    height: 42px;
    left: 50%;
    top: 0px;
    transform: translate(-50%, 0);
    background: linear-gradient(
      180deg,
      #0074b5 0%,
      #13a4f4 53.65%,
      #13a4f4 100%
    );
    border-radius: 0px 0px 50px 50px;
  }

  div.desc {
    padding: 15px;
    text-align: center;
    color: white;
  }

  .headerjudulbanner {
    position: absolute;
    width: 300px;
    height: 66px;
    left: 50%;
    color: white;
    top: 0px;
    font-size: 50px;
    transform: translate(-50%, 0%);
    background: rgba(73, 124, 231, 0.67);
    border-radius: 0px 0px 80px 80px;
  }

  .headerjudulbanner h1 {
    font-size: 16px;
  }

  .gpt3__galery {
    font-size: 13px;
    color: white;
    font-weight: bold;
  }

  .galery-title {
    font-size: 14px !important ;
  }
}
