/* title logistik */
.logistic-title {
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: flex-end;
  font-size: 50px;
}

.container-logistic {
  width: 80%;
  margin-bottom: 250px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

/* end logistik */

.gpt3__header {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}

.btn-header {
  width: 256px;
  height: 42px;
  background: linear-gradient(90deg, #008bd9 25%, #56c3ff 81.35%);
  border-radius: 10px;
  color: white;
}

.gpt3__header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-items: flex-start; */
  flex-direction: column;
}
.text-hubungikami {
  font-size: 18px;
  color: white;
}
.gpt3__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04rem;
  margin-top: 0px;
  text-align: center;
  color: white;
}

.gpt3__header-content_home h1 {
  margin-top: 200px;
  text-align: center;
  color: white;
}
.gpt3__header-content p {
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  color: var(--color-text);
  margin-top: 1.5rem;
  text-align: center;
}

.container-hubungikami {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gpt3__header_hubungi_kami {
  text-align: center;
  width: 156px;
  height: 42px;
  background: linear-gradient(90deg, #008bd9 25%, #56c3ff 81.35%);
  border-radius: 0px 20px;
  margin-top: 5px;
}

.gpt3__header_hubungi_kami p {
  margin-top: 0px;
  font-size: 17px;
  font-weight: bold;
  color: white;
}
.gpt3__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}
.gpt3__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  background: var(--color-footer);
  color: #fff;
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.gpt3__header-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-weight: bold;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  background: #ff4820;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  border: none;
}
.gpt3__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}
.gpt3__header-content__people img {
  width: 181px;
  height: 38px;
}
.gpt3__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #ffffff;
  text-align: center;
}
.gpt3__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gpt3__header-image img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 850px) {
  .gpt3__header {
    width: 100%;
    height: 70%;
    display: flex;
    margin-top: 70px;
    justify-content: center;
  }

  .logistic-title {
    display: flex;
    color: #ffffff;
    justify-content: center;
    align-items: flex-end;
    font-size: 20px;
  }
}
