.footer_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: var(--color-footer);
}
.gpt3__footer {
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  
}
.gpt3__footer-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}
.gpt3__footer-heading h1 {
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  text-align: center;
  letter-spacing: -0.04rem;
}
.gpt3__footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #fff;
  text-align: center;
  margin-bottom: 10rem;
  cursor: pointer;
}
.gpt3__footer-btn p {
  box-sizing: border-box;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  word-spacing: 2px;
}
.gpt3__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  text-align: left;
}
.gpt3__footer-links div {
  width: 250px;
  margin: 1rem;
}
.gpt3__footer-links_logo {
  display: flex;
  flex-direction: column;
}
.gpt3__footer-links_logo img {
  width: 150px;
  height: 40px;
  margin-bottom: 1rem;
  margin-top: -50px;
}
.gpt3__footer-links_logo p {
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.gpt3__footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.gpt3__footer-links_div h4 {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 1rem;
}
.gpt3__footer-links_div p {
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  margin: 0.5rem 0;
  cursor: pointer;
}
.gpt3__footer-copyright {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}
.gpt3__footer-copyright p {
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

/* Footer logo */

/* End Footer logo */

/* Margin left */
.footer-mr-2 {
  margin-right: 50px;
}
/* End Margin left */

/* Footer logo */
.footer-logo {
  width: 100%;
  height: 100%;
}
/* End Footer logo */

/* Footer media sosial */
.footer-media-sosial {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 80%;
}

.footer-media-2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-social-img {
  width: 40px;
  height: 40px;
}

.footer-nama-perusahaan {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.container-footer-nama-perusahaan {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

/* End footer media sosial */

/* Extension footer */
.title-footer {
  font-weight: bold;
}

.desc-footer {
  font-size: 17px;
}

.mb-footer {
  margin-bottom: -25px;
}

.title-mediasosial-footer {
    font-size: 20px;
    margin-bottom: 10px;
  }
/* End extension footer */

@media screen and (max-width: 850px) {
  .gpt3__footer-heading h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .footer-nama-perusahaan {
    display: flex;
    flex-direction: column;
    margin-bottom: -10px;
  }
  .container-footer-nama-perusahaan {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    height: fit-content;
  }
  .media-social-img {
    width: 25px;
    height: 25px;
  }
  .footer-media-2 {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-media-sosial {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }

  .fontkonsumen {
    font-size: 25px;
    font-weight: normal;
  }

  /* Extension footer */
  .title-footer {
    font-weight: bold;
  }

  .desc-footer {
    font-size: 15px;
  }

  .mb-footer {
    margin-bottom: 20px;
  }

  .title-mediasosial-footer {
    font-size: 15px;
  }
  /* End extension footer */
}
@media screen and (max-width: 550px) {
  .footer-media-sosial {
    size: 10px;
  }

  .gpt3__footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .gpt3__footer-links div {
    margin: 1rem 0;
  }
  .gpt3__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }

  .fontkonsumen {
    font-size: 13px;
    font-weight: normal;
  }
}
@media screen and (max-width: 550px) {
  .gpt3__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }
}

.textsize {
  font-size: 13px;
}
