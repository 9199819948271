#header {
  position: absolute;
  width: 220px;
}
#header .pro-sidebar {
  height: 350vh;
}
#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;


}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 0px 0px;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: white;
  color: #000;
  border-radius: 3px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #46BCFE 0%, #0386CE 100%);
}
#header .logo {
  padding: 20px;
}

/* @media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
} */

.navbar{
  width:1400px !important;
  height:90px;
  margin:0px auto;
  left:0;
  right:0;
  padding:0;
  background-color: rgba(0, 139, 217, 0.74);
}

.box_1_header{
background: linear-gradient(180deg, #46BCFE 0%, #0386CE 120%);
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 20px 20px 0px 0px;
width: 550px;
margin-left:0px;
height:60px;

}


.box_1_pesanansaya{
  background: linear-gradient(180deg, #E7E7E7 120%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 0px 0px;
  width: 1200px;
  margin-left:0px;
  height:60px;
  
  }

  
.box_1_pembayaran{
  background: linear-gradient(180deg, #E7E7E7 120%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 0px 0px;
  width: 1200px;
  margin-left:0px;
  height:60px;
  
  }

.icon_1 {
  padding-left: 25px;
  background: url("../../../../assets/image/icons/Vector(1).png") no-repeat left;
  background-size: 20px;
 

}


.icon_2 {
  padding-left: 25px;
  background: url("../../../../assets/image/icons/Vector(2).png") no-repeat left;
  background-size: 20px;
}

.icon_3 {
  padding-left: 25px;
  background: url("../../../../assets/image/icons/Vector(3).png") no-repeat left;
  background-size: 20px;
}
.icon_4 {
  padding-left: 25px;
  background: url("../../../../assets/image/icons/Vector(4).png") no-repeat left;
  background-size: 20px;
}
.icon_5 {
  padding-left: 25px;
  background: url("../../../../assets/image/icons/Vector(5).png") no-repeat left;
  background-size: 20px;
}
.icon_6 {
  padding-left: 25px;
  background: url("../../../../assets/image/icons/Vector(6).png") no-repeat left;
  background-size: 20px;
}

.icon_7 {
  padding-left: 25px;
  background: url("../../../../assets/image/icons/Vector(7).png") no-repeat left;
  background-size: 20px;
}

.box_dashboard{

  left: 0%;
  right: -2.39%;
  top: 5.88%;
  bottom: -5.88%;
  background:#FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 550px;
  height: 200px;



}

.box_pesanansaya{

  left: 0%;
  right: -2.39%;
  top: 5.88%;
  bottom: -5.88%;
  background:#FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 1200px;
  height: 1600px;



}

.box_pembayaran{

  left: 0%;
  right: -2.39%;
  top: 5.88%;
  bottom: -5.88%;
  background:#FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 1200px;
  height: 680px;

}

.submitbutton{
width: 183px;
height: 49px;
left: 0px;
top: 1111px;

background: #008BD9;
border-radius: 7.98701px;
color:white;
margin-left:950px;

}

.button_header{
width: 150px;
height: 45px;
left: 257px;
top: 525px;
background: #008BD9;
border-radius: 10px;


}

.papanexpiredtime{
width: 420px;
height: 106.8px;
left: 620px;
top: 513px;

background: #59C8A7;
box-shadow: 0px 1.2px 12px rgba(0, 0, 0, 0.15);
border-radius: 6px;
}

.boxinformasi{
border:1 px black;
box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
width: 800px;
height: 400px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border: 2px solid transparent;
text-decoration: none;
color: #615f5fdd;
  
}

.boxstatuspembayaran{
  border:1 px black;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  text-decoration: none;
  color: #615f5fdd;
    
  }

  .lihatpesanan{

width: 247.18px;
height: 45.34px;
left: 701.55px;
top: 1084.46px;
background: #008BD9;
border-radius: 8.29457px;
color:white;
  }

.navbar {
  background-color: #3b5998;
  font-size: 22px;
  padding: 5px 10px;
}

/* Define what each icon button should look like */
.button {
  color: white;
  display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
  position: relative; /* All 'absolute'ly positioned elements are relative to this one */
  padding: 2px 5px; /* Add some padding so it looks nice */
}

/* Make the badge float in the top right corner of the button */
.button__badge {
  background-color: #fa3e3e;
  border-radius: 50%;
  width:20px;
  color: white;
 
  padding: 1px 3px;
  font-size: 12px;
  
  position: absolute; /* Position the badge within the relatively positioned button */
  top: 5px;
  right: -20px;
 
}

.gambarproduk{
  width:50%;
  height:50%;
}

.tranfer_setclass{
 box-sizing: border-box;
width: 501.86px;
height: 45.88px;
left: 284px;
top: 562.55px;

border: 1.4339px solid #ACACAC;
border-radius: 7.1695px;
}

.buttonpesanan{
width: 155px;
height: 35px;
left: 1223px;
top: 897px;
background: #008BD9;
border-radius: 5px;
}



.bg_dashboard{

  background-image: url("../../../../assets/image/bg-dashboard.png");
  background-repeat: no-repeat;
  height:400px ;
  max-width: 2000px;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}

.bg_layerdashboard{

  height:400px ;
  width: 1400px;
  background-color: rgba(0, 139, 217, 0.28) !important;



}


.body_pesanan {
  
  width: 720px;
  height: 440px;
  margin: 0px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color:white;
 
}


.body_ketentuan {
  
  width: 900px;
  height: 440px;
  margin: 0px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color:white;
 
}


.body_informasibarang {
  
  width: 900px;
  height: 540px;
  margin: 0px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color:white;
 
}


.body_kirimindo {
  
  width: 900px;
  height: 520px;
  margin: 0px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color:white;
 
}

.box-kirimindo{

  
  width: 900px;
  height: 420px;
  margin: 0px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color:white;
 

}


.body_rangkuman {
  
  width: 900px;
  height: 480px;
  margin: 0px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color:white;
 
}

.box-rangkuman{

  
  width: 900px;
  height: 480px;
  margin: 0px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color:white;
 

}

.tipeekpedisi{
  border-radius: 100%;
  color: rgb(0,178,0);
  
}

.lanjut_tombol{

width: 461px;
height: 60px;
left: 601px;
top: 869px;

background: #008BD9;
border-radius: 5px;


}

.setlaut{

  width:87px;
  height:33px;
  float:left;
  margin:5px;
  margin-top:15px;


}
.labelkirimlaut{

  margin-top:20px;
  margin-left:0px;

}

.setudara{

  width:45px;
  height:45px;
  float:left;
  margin:5px;


}

.setlautkirim{

  width:45px;
  height:45px;
  float:left;
  margin:5px;



}

.labelkirimudara{

  margin-top:20px;
  margin-left:0px;

}

.boxkirimindonesia{
 box-sizing: border-box;


width: 422px;
height: 63px;
left: 381px;
top: 612px;

border: 1.20572px solid #ACACAC;
border-radius: 6.02858px;
}


.body_dashboard {
  
  width: 500px;
  height: 200px;
  margin: 0px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color:white;
 
}


.box-ketentuan {
  width: 100%;
  height:100%;
 
  ;
}

.box-dashboard {
  width: 50%;
  height:50%;
 
  ;
}

p {
  color: black;
  text-indent: 0px;
  font-size:10px;
  text-justify:center;
}

.submit_informasibarang{

 width: 461px;
height: 46px;
left: 640px;
top: 1071px;

background: #008BD9;
border-radius: 5px;


}
/* timeline css */


