body {
  background: var(--color-bg);
}

.gradient__bg_tentangkami {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    white 100%
  );

  background-image: url("../../../assets/image/bg-layanan.png");
  background-repeat: no-repeat;
  height: 600px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: 50px;
}

.container-tentangkami {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
}

.border_garis_tentangkami {
  border-style: inset;
  width: 15%;
  height: 0px;
  left: 800px;
  top: 600px;
  border: 5px solid #ffffff;
  margin: auto;
  color: white;
}

.gpt3__whatgpt3_tentangkami {
  background-color: rgb(15, 143, 215);
  margin-top: 330px;
  height: 280px;
  padding-top: 42px;
}

.gpt3__whatgpt3_tentangkami_griddua {
  background-color: rgb(15, 143, 215);
  height: 250px;
  padding-top: 42px;
}
.container-tentang-kami {
  display: flex;
  align-items: center;
  width: 1200px;
  height: 369px;
  background: linear-gradient(to right, #58B5E9, #0F8FD7);
}
.container-utama-tentang-kami {
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
  height: 369px;

}

.desc-tentang-kami {
  font-size: 19px;
  padding: 30px;

}

.responsive-img {
  max-width: 100%;
  margin-top: 100px;
}

.distance_text_kedua_gridsatu {
  margin-top: -20px;
}

.distance_gambar_kedua {
}

.tentangkami-container {
  font-size: medium;
}

@media (max-width: 800px) {
  .container-tentang-kami {
    display: flex;
    flex-direction: column-reverse;
  }

  .container-utama-tentang-kami {
    margin-top: 500px;
  
  }

  .container-tentang-kami2 {
    display: flex;
    flex-direction: column;
  }
  .container-tentang-kami {
    display: flex;
    align-items: center;
    width: 100%;
    background:none;
  }
  .desc-tentang-kami {
    background: linear-gradient(to right, #58B5E9, #0F8FD7);
  
  }
  .gradient__bg_tentangkami {
    margin-bottom: 500px;
  }
}

@media (max-width: 500px) {
  .distance_gambar {
    display: flex;
    justify-content: center;
  }

  .distance_gambar_kedua {
    margin-top: 160px;
  }
  .responsive-img {
    max-width: 100%;
    margin-top: 0;
  }

  .distance_text_kedua {
    margin-top: 600px;
  }

  .distance_text_kedua_griddua {
    margin-top: -450px;
  }

  .gpt3__whatgpt3-container {
    
  }

  .gpt3__whatgpt3_tentangkami {
    height: 370px;
  }
}


