*{
    box-sizing:border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  
}
body{
    background: #E7E7E7;
}
a{
    color: unset;
    text-decoration: none;
}
.gradient__bg{
    background-image: url("../../src/assets/image/unsplash_FPKnAO-CF6M.png");
    background-repeat: no-repeat;
    height:80vh ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   

    

}
.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: none;
    margin-top:-10px;
    
}
.section__padding{
    padding:4rem 6rem;
    color:white;
}
.section__margin{
    margin: 4rem 6rem;
    margin-top:-100px;
    max-width:1200px;
    margin-bottom:50px;
    margin:auto;
}

.section__margin_fitur{
  margin: 2rem 2rem;
  margin-top:100px;
  max-width:1000px;
  margin:auto;
}

.section__margin_login{

  max-width:600px;
  margin:auto;
  margin-top:-700px;


}

.section__margin_tentangkami{

  margin: 2rem 2rem;
  margin-top:0px;
  max-width:1200px;
  margin:auto;
  margin-bottom: 50px;



}

.section__margin_galery{
  margin: 2rem 2rem;
  margin-top:100px;
  max-width:1200px;
  margin:auto;
}
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @media screen and (max-width:700px){
    .section__padding{
        padding: 4rem;
    }
    .section__margin{
        margin: 4rem;
    }
  }
  
  @media screen and (max-width:550px){
    .section__padding{
        padding: 4rem 2rem;
    }
    .section__margin{
        margin: 4rem 2rem;
    }
  }
  

