.gpt3__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
}
.gpt3__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.gpt3__navbar-links_logo {
  margin-right: 2rem;
  width: 200px;
  height: 100px;
}
.gpt3__navbar-links_logo img {
  width: 154px;
  height: 55px;
}
.gpt3__navbar-links_container {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  width: 900px;
  margin-top: -40px;
}
.gpt3__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: none;
}
.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
  /* color:white;*/
  font-weight: bold;
}
.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
}
.gpt3__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}
.register-btn {
  text-align: center;
  width: 186px;
  border-radius: 10px;
  height: 42px;
  background: linear-gradient(90deg, #1fadff 25%, hsl(192, 100%, 50%) 81.35%);
}
.container-register-btn {
  margin-left: 50px;
  display: flex;
  justify-content: center;
}
.gpt3__navbar-menu svg {
  cursor: pointer;
}
.gpt3__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1 rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgb(0, 0, 0, 0.2);
  z-index: 10;
}
.gpt3__navbar-menu_container p {
  margin: 1rem 0;
}
.gpt3__navbar-menu_container-links-sign {
  display: none;
}

p a:hover {
  background-color: transparent;
  background: linear-gradient(90deg, #008bd9 40%, #56c3ff 100%);
  border-radius: 15px;
  padding: 10px 10px;
}

p {
  padding: 10px;
}
p.button {
  padding: 9px 5px;
}
p:not(.button) a:hover,
p a:hover::after {
  color: #ccc;
}

@media screen and (max-width: 1050px) {
  .gpt3__navbar-links_container {
    display: none;
  }
  .gpt3__navbar-menu {
    display: flex;
  }
}
@media screen and (max-width: 700px) {
  .gpt3__navbar {
    padding: 2rem 4rem;
  }



  .gpt3__navbar-links_logo {
    margin-right: 2rem;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
@media screen and (max-width: 550px) {
  .gpt3__navbar {
    padding: 2rem;
  }
  .gpt3__navbar-sign {
    display: none;
  }
  .gpt3__navbar-menu_container {
    top: 20px;
  }
  .gpt3__navbar-menu_container-links-sign {
    display: block;
  }

  .gpt3__navbar-links_container {
    display: none;
  }
    .register-btn {
    text-align: center;
    width: 186px;
    border-radius: 10px;
    height: 42px;
    font-size: 10px;
    background: linear-gradient(90deg, #1fadff 25%, hsl(192, 100%, 50%) 81.35%);
  }
}
