
.gradient__bg_login{

    

     background-image: url("../../../../assets/image/login_bg.png");
     background-repeat: no-repeat;
     height:800px ;
 
     max-width: 1200px;
     margin:auto;

     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;




}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif
}

.login_masuk{

    width: 150px;
    height: 49px;
    left: 900px;
    top: 580px;
    background: #008BD9;
    border-radius: 30px;
    }


.body {
  
    width: 720px;
    height: 440px;
    margin: 0px auto;
    border: 1px solid #dddd;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #008BD9A6;
    color:white;
   
}

.icon_user {
    padding-left: 40px;
    background: url("../../../../assets/image/icons/Vector(1).png") no-repeat left;
    background-size: 20px;
    margin-left:10px;
  
  }
  

.icon_email {
    padding-left: 40px;
    background: url("../../../../assets/image/icons/email.png") no-repeat left;
    background-size: 20px;
    margin-left:10px;
  
  }
  
.icon_password {
    padding-left: 40px;
    background: url("../../../../assets/image/icons/password.png") no-repeat left;
    background-size: 20px;
    margin-left:10px;
  
  }
  
.icon_confirmpassword {
    padding-left: 40px;
    background: url("../../../../assets/image/icons/password.png") no-repeat left;
    background-size: 20px;
    margin-left:10px;
  
  }
  .icon_call {
    padding-left: 40px;
    background: url("../../../../assets/image/icons/phone.png") no-repeat left;
    background-size: 20px;
    margin-left:10px;
  
  }
  
  


.body-register {
  
    width: 720px;
    height: 540px;
    margin: 0px auto;
    border: 1px solid #dddd;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    color:white;
   
}

.box-register {
    width: 100%;
   
    ;
}


.box-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.box-2 {
    padding: 10px;
    background-color: white;
}



.box-1,
.box-2 {
    width: 50%;
   
    ;
}

.h-1 {
    font-size: 24px;
    font-weight: 700;
}

.text-muted {
    font-size: 14px;
}

.container .box {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    text-decoration: none;
    color: #615f5fdd;
}

.box:active,
.box:visited {
    border: 2px solid #ee82ee;
}

.box:hover {
    border: 2px solid #ee82ee;
}

.btn.btn-primary {
    background-color: transparent;
    color: #ee82ee;
    border: 0px;
    padding: 0;
    font-size: 14px;
}

.btn.btn-primary .fas.fa-chevron-right {
    font-size: 12px;
}

.footer .p-color {
    color: #ee82ee;
}

.footer.text-muted {
    font-size: 10px;
}



@media (max-width:767px) {
    body {
        padding: 10px;
    }

    .body {
        width: 100%;
        height: 100%;
    }

    .box-1 {
        width: 100%;
    }

    .box-2 {
        width: 100%;
        height: 440px;
    }

    .body-register {
  
        width: 300px;
        height: 440px;
        margin: 0px auto;
        border: 1px solid #dddd;
        border-radius: 18px;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: #008BD9A6;
        color:white;
       
    }
    
    .box-register {
        width: 100%;
       
        ;
    }
}


.inputfield {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .input {
    height: 3em;
    width: 3em;
    border: 2px solid #dad9df;
    outline: none;
    text-align: center;
    font-size: 1.5em;
    border-radius: 0.3em;
    background-color: #ffffff;
    outline: none;
    /*Hide number field arrows*/
    -moz-appearance: textfield;
  }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:disabled {
  color: #89888b;
}
.input:focus {
  border: 3px solid #ffb800;
}